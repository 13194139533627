import { createContext } from 'react';
import { useContextModel } from '../context';

export * from './use-device';
export * from './use-editor-state';
export * from './use-event';
export * from './use-global-state';
export * from './use-icon';
export * from './use-image';
export * from './use-live-event';
export * from './use-menu';
export * from './use-page';
export * from './use-preload-page';
export * from './use-route';
export * from './use-scroll-top';
export * from './use-variable';
export * from './use-store';
export * from './use-viewport';
export * from './use-widget';
export * from './use-zoom';

export * from '@vev/hooks';
type VirtualModelContext = any;
export const VirtualModelContext = createContext<VirtualModelContext | undefined>(undefined);
/**
 * `useModel` watches and returns the current or given content model
 */
export const useModel = useContextModel;
