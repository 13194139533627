import { Variable } from './types';
import { getStateVariables, ModelVariables } from './get-state-variables';
import { attributePathToObject } from './attribute-path-to-object';
import { isEqual } from 'lodash';

declare global {
  interface Window {
    variable: VariableManagerImpl;
    variable_dbg: boolean;
    dispatch: any;
  }
}

class VariableManagerImpl {
  private variables: Map<string, Variable> = new Map();
  private stateVariables: ModelVariables = {};
  private initialVariables: Variable[] = [];
  private windowRef: Window;

  constructor() {
    this.log('Initialized VariableManager');
    this.windowRef = window;
  }

  initializeVariables(initialVariables: Variable[]): void {
    this.log('Initialize Variables', initialVariables);
    this.variables = new Map();
    this.initialVariables = initialVariables;
    initialVariables.forEach((variable) => {
      this.variables.set(variable.key, variable);
    });
    this.updateStateVariables();
  }

  bindWindow(bindWindow: Window): void {
    this.windowRef = bindWindow;
  }

  setVariable(key: string, variable: Partial<Variable>) {
    this.log('Set variable', variable);
    const prev = this.variables.get(key);
    if (prev && prev.value !== variable.value) {
      const update = { ...prev, ...variable };
      this.variables.set(key, update);
      this.dispatchChange(update);
    } else {
      this.variables.set(key, variable as Variable);
      this.dispatchChange(variable as Variable);
    }
  }

  updateStateVariables(): void {
    // @ts-expect-error - Works
    const states = this.windowRef.vevs || this.windowRef.vevStates;

    const newModelVariables = getStateVariables(this.initialVariables, states);
    Object.keys(newModelVariables).forEach((variableKey) => {
      const updatedModelVariables = newModelVariables[variableKey];
      updatedModelVariables.forEach((updatedModelVariable) => {
        Object.keys(this.stateVariables).forEach((key, index) => {
          this.stateVariables[key] = this.stateVariables[key].filter((stateVariable) => {
            return !isEqual(stateVariable, updatedModelVariable);
          });
        });
      });
    });

    Object.keys(newModelVariables).forEach((variableKey) => {
      if (this.stateVariables[variableKey]) {
        this.stateVariables[variableKey] = [
          ...this.stateVariables[variableKey],
          ...newModelVariables[variableKey],
        ];
      } else {
        this.stateVariables[variableKey] = [...newModelVariables[variableKey]];
      }
    });

    if (Object.keys(newModelVariables).length) {
      Object.keys(this.stateVariables).forEach((variableKey) => {
        const variable = this.variables.get(variableKey);
        if (variable) {
          this.dispatchContentChanges(variable);
        }
      });
    }
  }

  getVariable(key: string) {
    return this.variables.get(key);
  }

  private dispatchChange(update: Variable) {
    // Update CSS variables
    if (update.type !== 'text') {
      document.documentElement.style.setProperty(`--vev-${update.key}`, update.value);
    }

    this.dispatchContentChanges(update);
    this.dispatchStateChanges(update);
  }

  /**
   * Updates the content for widget schema fields that are connected to a variable
   * but does not use the variable schema field
   */
  private dispatchContentChanges(update: Variable) {
    // Update content variables
    if (this.stateVariables[update.key]) {
      this.stateVariables[update.key].forEach((modelVariable) => {
        const updateObject = attributePathToObject(modelVariable.path, update.value);
        Object.keys(this.windowRef.dispatch).forEach((stateKey: string) => {
          const dispatch = this.windowRef.dispatch[stateKey];
          dispatch('widget-content', {
            modelKey: modelVariable.modelKey,
            content: updateObject,
          });
        });
      });
    }
  }

  /**
   * Updates the app state for anything using the global store (useVariable hook)
   */
  private dispatchStateChanges(update: Variable) {
    const variables: Variable[] = Array.from(this.variables.values()).filter(
      (v) => v.key !== update.key,
    );

    variables.push(update);

    Object.keys(this.windowRef.dispatch).forEach((stateKey: string) => {
      const dispatch = this.windowRef.dispatch[stateKey];
      dispatch('variables', variables);
    });
  }

  private log(...data: any[]) {
    if (window.variable_dbg) {
      console.log('🟢 VariableManager | ', ...data);
    }
  }
}

export const VariableManager = new VariableManagerImpl();
